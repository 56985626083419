





































import Vue from 'vue';
import AppModal from '@/components/AppModal.vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import List from '@/components/list/List.vue';
import { RequestConfig, TableColumn } from '@/types/list';
import Btn from '@/components/buttons/Btn.vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';

export default Vue.extend({
  name: 'AddFilesToResource',
  components: {
    List,
    DescriptionCol,
    Btn,
    AppModal,
  },
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    params: {
      type: [Object, Function],
      required: false,
    },
  },
  computed: {
    requestConfig(): RequestConfig {
      return {
        url: `project/${this.projectId}/files/info?notAssetIds=${this.resourceId}`,
      };
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    extraColumns(): TableColumn[] {
      return [
        {
          name: 'description',
          field: 'description',
          label: 'Description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'format',
          label: 'Format',
          field: 'format',
          align: 'center',
          sortable: true,
        },
      ];
    },
  },
  methods: {
    fileSizeInMb(size: number) {
      return `${(size / (1024 * 1024)).toFixed(2)}Mb`;
    },
    async addFileToResource(file: any, loadRows: () => void) {
      try {
        await ApiService.put(`project/${this.projectId}/asset/${this.resourceId}/link`, { fileIds: [file.id] });
        notify.success(`${file.label} has been linked to this resource`);
        await loadRows();
        await this.$emit('changed');
      } catch (e: any) {
        notify.danger('The file could not be linked.');
      }
    },
  },
});
