















































import Vue, { PropType } from 'vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import AppCard from '@/components/card/AppCard.vue';
import List from '@/components/list/List.vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import { RequestConfig, TableColumn } from '@/types/list';
import SecurityLevelCol from '@/components/list/SecurityLevelCol.vue';
import AddFilesToResource from '@/views/resources/files/AddFilesToResource.vue';
import RemoveResourceFromFile from '@/views/projects/files/resources/RemoveResourceFromFile.vue';

export default Vue.extend({
  name: 'ListFilesForResource',
  components: {
    AppCard,
    SecurityLevelCol,
    List,
    DescriptionCol,
    AddFilesToResource,
    RemoveResourceFromFile,
  },
  props: {
    // There are some cases where we need to display a resource
    // outside the resource views that have access
    // to the resourceId param.
    resource: {
      type: Object as PropType<{ id: string; }>,
      required: false,
    },
  },
  computed: {
    requestConfig(): RequestConfig {
      return {
        url: `project/${this.projectId}/files/info?assetIds=${this.resourceId}`,
      };
    },
    resourceId(): string {
      if (this.resource && this.resource.id) {
        return this.resource.id;
      }
      return this.$route.params.resourceId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    extraColumns(): TableColumn[] {
      return [
        {
          name: 'description',
          field: 'description',
          label: 'Description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'size',
          label: 'Size',
          field: 'size',
          align: 'center',
          sortable: true,
        },
        {
          name: 'securityLevel',
          label: 'Security Level',
          field: 'securityLevel',
          align: 'center',
          sortable: true,
        },
        {
          name: 'version',
          field: 'version',
          label: 'Version',
          align: 'left',
          sortable: false,
        },
        {
          name: 'format',
          label: 'Format',
          field: 'format',
          align: 'center',
          sortable: true,
        },
        {
          name: 'uses',
          label: 'Uses',
          field: 'uses',
          align: 'center',
          sortable: true,
        },
      ];
    },
  },
  methods: {
    fileSizeInMb(size: number) {
      return `${(size / (1024 * 1024)).toFixed(2)}Mb`;
    },
    async goToFile(event: any) {
      const { orgId, projectId } = this.$route.params;
      await this.$router.push(`/org/${orgId}/project/${projectId}/file/${event.id}`);
    },
    async removeFileFromResource(files: any[], loadRows: () => void) {
      try {
        const fileIds = files.map((r) => r.id);
        await ApiService.put(`project/${this.projectId}/asset/${this.resourceId}/unlink`, { fileIds });
        notify.success('File(s) have been unlinked from this resource');
        await loadRows();
      } catch (e: any) {
        notify.danger('The file(s) could not be unlinked.');
      }
    },
  },
});
